<template>
  <div class="view">
    <div class="content">
      <logo-banner/>
      <div class="cameraView">
        <div ref="qrWrapper" class="qrWrapper" @click="finalizeScan()">
          <b-img ref="qrCode" class="qr" style="opacity: 0" src="~@/assets/qr.png" width="250"/>
        </div>
      </div>
      <div class="wrapper">
        <b-button class="mt-3" variant="danger" block to="index">Abbrechen</b-button>
      </div>
    </div>
    <b-modal id="checkinModal" title="Checkin" hide-footer>
      <p class="my-4">Sie haben sich eingechecked!</p>
      <b-button class="mt-3" variant="success" block @click="checkinAndRedirect">OK</b-button>
    </b-modal>
    <b-modal id="testModal" title="Checkin" hide-footer>
      <p class="my-4">Sie haben erfolgreich ihren Test eingetragen!</p>
      <b-button class="mt-3" variant="success" block @click="saveTestAndRedirect">OK</b-button>
    </b-modal>
  </div>
</template>

<script>
import LogoBanner from "../components/LogoBanner";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "Scan",
  props: {
    type: {
      type: String,
      default: 'back'
    }
  },
  components: {
    LogoBanner,
  },
  computed: {
    ...mapGetters([
      'getCheckIns',
      'getTests'
    ]),
  },
  mounted() {
    setTimeout(()=> {
      this.showQR()
      setTimeout(()=> {
        this.blink()
        setTimeout(()=> {
          this.finalizeScan()
        },1800)
      },500)
    },500)
  },
  methods: {
    ...mapMutations([
        'addCheckIn',
        'addTest'
    ]),
    showQR() {
      this.$refs.qrCode.removeAttribute('style')
    },
    blink() {
      this.$refs.qrWrapper.setAttribute('style', 'animation: blinker 1s linear infinite;')
    },
    finalizeScan () {
      this.$refs.qrWrapper.removeAttribute('style')

      switch (this.type) {
        case 'checkIn': this.successCheckin(); break;
        case 'test': this.successTest(); break;
        default: alert('error'); break;
      }
    },
    successCheckin () {
      this.$bvModal.show("checkinModal")
    },
    successTest () {
      this.$bvModal.show("testModal")
    },
    checkinAndRedirect() {
      let checkin = {
        id: this.getCheckIns.length + 1,
        name: "Location" + (this.getCheckIns.length + 1),
        date: (new Date()),
        defaultStayTime: this.randomIntFromInterval(15, 20),
      }
      this.addCheckIn(checkin)
      this.$router.push('Index')
    },
    saveTestAndRedirect() {
      let test = {
        id: this.getTests.length + 1,
        date: (new Date()),
        result: 'Negativ',
        testNumber: this.randomIntFromInterval(1000, 5000),
        testCenter: "TestCenter",
      }
      this.addTest(test)
      this.$router.push('Tests')
    },
    randomIntFromInterval(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    }
  }
}
</script>

<style scoped>
.content {
  height: 100%;
  max-height: 100%;
}

.helpText {
  position: absolute;
  top: 22%;
  color: #848484;
  width: 70%;
  text-align: center;
}

.cameraView {
  height: 72%;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qrWrapper {
  padding: 16px;
  background:
      linear-gradient(to right, white 4px, transparent 4px) 0 0,
      linear-gradient(to right, white 4px, transparent 4px) 0 100%,
      linear-gradient(to left, white 4px, transparent 4px) 100% 0,
      linear-gradient(to left, white 4px, transparent 4px) 100% 100%,
      linear-gradient(to bottom, white 4px, transparent 4px) 0 0,
      linear-gradient(to bottom, white 4px, transparent 4px) 100% 0,
      linear-gradient(to top, white 4px, transparent 4px) 0 100%,
      linear-gradient(to top, white 4px, transparent 4px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.qr {
  transition: opacity 200ms ease;
}
</style>


<style>
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>